body {
  margin: 0;
  font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;
  min-height: 100%;
  background-color: #fffbf2;
  background-size: 1440px 1026px;
  background-image: url(https://kokoa.finance/static/img/pattern.6a0c636.png);
  background-blend-mode: multiply;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
